<template>
  <div class="sidebar">
    <h2 class="heading-2">
      {{ user.firstName }}
    </h2>

    <ul>
      <li v-for="link in links" :key="link.title">
        <router-link :to="{name: link.route}">
          {{ link.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  const links = require("@/data/nav-links.json");

  export default {
    name: "Sidebar",
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        links
      };
    }
  };
</script>

<style lang="scss">
  .sidebar {
    width: 200px;
    padding: 1rem 0 0 0;
    flex-shrink: 0;

    h2 {
      padding: 0 16px;
    }

    a {
      border-radius: 4px;
      font-weight: 700;
      padding: 16px;
      display: block;
      margin: 0 0 8px;

      &:hover {
        background: rgba(#000, 0.03);
      }

      &.router-link-active {
        color: #00989b;
        background: rgba(#00989b, 0.10);
      }
    }
  }
</style>
