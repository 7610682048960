<template>
  <div v-if="user" class="settings">
    <Nav :user="user" />
    <div class="settings-layout pt-11 pt-md-16">
      <Sidebar :user="user" />
      <div class="settings-content">
        <router-view :user="user" />
      </div>
    </div>
  </div>
</template>

<script>
  import Nav from "@/components/Settings/components/Nav";
  import Sidebar from "@/components/Settings/components/Sidebar";

  export default {
    name: "SettingsScreen",
    components: { Sidebar, Nav },
    props: {
      user: {
        type: Object,
        default: null
      }
    }
  };
</script>

<style lang="scss">
  body {
    background: #ffffff;
  }

  .settings {
    &-layout {
      box-sizing: border-box;
      padding: 0 16px 16px 16px;
      display: flex;
      flex-direction: row;
      max-width: 1024px;
      margin: 0 auto;

      @media only screen and (max-width: 800px) {
        flex-direction: column;
      }

      .sidebar {
        margin: 0 16px 0 0;
        @media only screen and (max-width: 800px) {
          display: none;
        }
      }
    }

    &-content {
      width: 100%;
    }
  }
</style>
